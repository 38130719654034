<template>
  <div class="service-category-setting">
    <PageTitle
      title="服務類別設定"
      cyBtn1="new-category-btn"
      btn="新增"
      data-testid="page-title_service-category-setting"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_category_name"
        clearable
        placeholder="輸入類別名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="serviceCategoryList" testName="預約服務類別">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="圖片" align="center">
          <template slot-scope="scope">
            <img style="margin: auto;" :width="`${imgSize}px`" :src="imgSource(scope.row.Image)" alt="">
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="isPublic" label="公開顯示" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? '是' : '否' }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="usePubApt" prop="isPubApt" label="跨境顯示" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPubApt)">{{ (scope.row.isPubApt)? '是' : '否' }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :testName="scope.row.name"
              @edit="openDialog('update'), syncFormData(scope.row), selectRow=scope.row, findServiceCategory()"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceCategoryCount"
        @pageChange="refresh"
      />
    </section>

    <image-cropper v-if="uploadDialog" :image="formData.img" @uploaded="getImage" @close="uploadDialog = false" />

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      data-testid="service-category-setting_edit-dialog"
      @close="resetForm()"
    >
      <section>
        <AiHintBlock
          v-if="dialogType === 'create'"
          :hintLoading="hintLoading"
          @confirm="handleConfirm"
        />
        <BaseElForm
          ref="form"
          v-loading="hintLoading"
          :model="formData"
          label-position="top"
          label-width="150px"
          :rules="formRules"
        >
          <BaseElFormItem label="圖片">
            <UploadButton
              data-testid="form-item-upload_image"
              cyUploadBtn="service-category-img-upload"
              cyUploadedImg="service-category-img-uploaded"
              :img="formData.avatar"
              :isAvatar="true"
              @change="loadImg"
            />
            <!-- <img :src="`https://bob-image.flightgoai-service.com/display/resize/80x/${formData.avatar.filename}`" alt="" srcset=""> -->
          </BaseElFormItem>
          <BaseElFormItem label="公開顯示">
            <BaseElSwitch
              v-model="formData.isPublic"
              testName="formData_isPublic"
              active-text="是"
              inactive-text="否"
            />
          </BaseElFormItem>
          <BaseElFormItem v-if="usePubApt" label="顯示於跨境預約流程">
            <BaseElSwitch
              v-model="formData.isPubApt"
              testName="formData_isPubApt"
              active-text="是"
              inactive-text="否"
            />
          </BaseElFormItem>
          <BaseElFormItem label="名稱" prop="name">
            <BaseElInput
              v-model="formData.name"
              testName="formData_name"
              :maxlength="68"
              show-word-limit
              placeholder="請輸入類別名稱"
            />
          </BaseElFormItem>

          <BaseElFormItem label="排序" prop="order">
            <BaseElInput v-model="formData.order" testName="formData_order" placeholder="請輸入順序" />
          </BaseElFormItem>

          <BaseElFormItem label="綁定服務項目" prop="services">
            <BaseElSelect
              v-model="formData.services"
              testName="formData_services"
              multiple
              value-key="id"
              collapse-tags
              placeholder="請選擇服務"
              no-data-text="暫無數據"
            >
              <BaseElSelectOption
                v-for="item in serviceList"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </BaseElSelect>
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton testName="edit-dialog_cancel-btn" plain @click="showDialog = false, resetForm(), formData.services=[]">
          返回
        </BaseElButton>
        <BaseElButton
          testName="edit-dialog_confirm-btn"
          type="primary"
          @click="dialogConfirm"
        >
          {{ (dialogType === 'create')? '新增' : '儲存' }}
        </BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceCategory(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import {
  CreateServiceCategory,
  GetServiceCategory,
  GetServiceCategoryCount,
  UpdateServiceCategory,
  FindServiceCategory,
  DeleteServiceCategory,
} from '@/api/serviceCategory'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { set, ref, reactive, onMounted, computed, toRefs } from 'vue'
import { keys, has, get, filter, includes } from 'lodash'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'
import { hint } from '@/config/hint'
import { useService } from '@/use/useService'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceCategorySetting',
  components: {
    ImageCropper,
    UploadButton,
    DeleteDialog,
    EmptyBlock,
    AiHintBlock,
  },
  setup () {
    const formData = reactive({
      img: null,
      isPublic: true,
      isPubApt: false,
      avatar: null,
      name: '',
      order: 100,
      id: '',
      services: [],
    })
    const selectRow = ref({
      id: '',
      ShopId: '',
    })
    const { shopId } = useShop()
    const { configurationHint, hintLoading, setData } = useAi()
    const { serviceList, getAllService } = useService()
    const { checkAction } = usePermissions()
    const usePubApt = computed(() => checkAction('admin.pubAptConfig.page'))

    const handleConfirm = async (payload) => {
      const res = await configurationHint(get(hint, 'appointmentCategory.key'), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData, res)
      const ids = get(res.hint, 'appointmentServiceIds')
      set(formData, 'services', filter(serviceList.value, item => includes(ids, item.id)))
    }
    const servicesList = computed(() => {
      if (formData.services.length === 0) return []
      return extractList('id', formData.services)
    })
    const findServiceCategory = async () => {
      try {
        const res = await FindServiceCategory({
          shopId: shopId.value,
          id: get(selectRow, 'value.id'),
        })
        set(formData, 'services', filter(res.AppointmentServices, item => !item.isRemove))
      } catch (error) {
        window.$message.error({
          message: error || error.message,
        })
      }
    }
    onMounted(async () => {
      // if (usePubApt.value) formData.isPubApt = true
      await getAllService()
    })
    return {
      handleConfirm,
      hintLoading,
      formData,
      serviceList,
      servicesList,
      findServiceCategory,
      selectRow,
      usePubApt,
    }
  },
  data: () => ({
    loading: false,
    nameSearch: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',

    serviceCategoryList: [],
    serviceCategoryCount: 0,

    // selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,
    imgSize: 90,

    // formData: {
    //   img: null,
    //   isPublic: true,
    //   avatar: null,
    //   name: '',
    //   order: 100,
    //   id: '',
    //   services: [],
    // },

    formRules: {
      name: noEmptyRules('請輸入類別名稱'),
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],

    },
  }),
  computed: {
    ...mapGetters([
      'shop',
    ]),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增服務類別',
          update: '編輯服務類別',
        },
      )
    },
    // servicesList () {
    //   if (this.formData.services.length === 0) return []
    //   return extractList('id', this.formData.services)
    // },
  },
  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },

    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },

    async refresh () {
      this.loading = true
      await this.getServiceCategory()
      await this.getServiceCategoryCount()
      this.loading = false
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.order = row.order
      this.formData.id = row.id
      this.formData.isPublic = row.isPublic
      this.formData.isPubApt = row.isPubApt
      this.formData.avatar = row.Image
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData.img = null
      this.formData.isPublic = true
      this.formData.isPubApt = false
      this.formData.avatar = null
      this.formData.name = ''
      this.formData.order = 100
      this.formData.id = ''
      this.formData.services = []
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createServiceCategory()
      if (type === 'update') this.updateServiceCategory()
      this.showDialog = false
    },

    //= > 取得服務類別
    async getServiceCategory () {
      this.loading = true
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetServiceCategory({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCategoryList = res
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 創建服務類別
    async createServiceCategory () {
      this.loading = true
      if (this.shop === '') this.$message.error('請先選擇店家')
      try {
        await CreateServiceCategory({
          shopId: this.shop,
          name: this.formData.name,
          image: (this.formData.avatar) ? this.formData.avatar.id : undefined,
          order: this.formData.order,
          isPublic: this.formData.isPublic,
          appointmentServices: this.servicesList,
          isPubApt: this.formData.isPubApt,
        })
        await this.refresh()
        this.loading = false
        this.$message.success('新增成功')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    // async findServiceCategory () {
    //   console.log('findServiceCategory1', this.serviceList)
    //   try {
    //     const res = await FindServiceCategory({
    //       shopId: this.shop,
    //       id: this.selectRow.id,
    //     })
    //     console.log('Before:', this.formData.services)
    //     console.log('res.AppointmentServices:', res.AppointmentServices)
    //     this.formData.services = res.AppointmentServices.filter(item => !item.isRemove)
    //     console.log('After:', this.formData.services)
    //     // console.log('findServiceCategory', res.AppointmentServices.filter(item => !item.isRemove))
    //     // this.formData.services = res.AppointmentServices.filter(item => !item.isRemove)
    //     // console.log(this.formData.services)
    //   } catch (error) {
    //     this.$message.error({
    //       message: error || error.message,
    //     })
    //   }
    // },
    //= > 刪除服務類別
    async deleteServiceCategory () {
      try {
        this.loading = true
        await DeleteServiceCategory({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.loading = false
        this.$message.success('刪除成功')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
      await this.refresh()
    },
    //= > 更新服務類別
    async updateServiceCategory () {
      try {
        this.loading = true
        await UpdateServiceCategory({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
          isPublic: this.formData.isPublic,
          isPubApt: this.formData.isPubApt,
          name: this.formData.name,
          image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
          order: this.formData.order,
          appointmentServices: this.servicesList,
        })
        this.loading = false
        await this.refresh()
        this.$message.success('更新成功')
      } catch (error) {
        this.$message.error(error)
        this.loading = false
      }
    },

    //= > 取得服務人員總數
    async getServiceCategoryCount () {
      try {
        this.loading = true
        const res = await GetServiceCategoryCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCategoryCount = res.data.count
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error(error.reponse.data.msg)
      }
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
